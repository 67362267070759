import { PropsWithChildren } from 'react'
import { card } from './Card.styles'

interface CardProps {
  composeCss?: Style
  className?: string
}

export function Card(props: PropsWithChildren<CardProps>) {
  return (
    <section
      className={props.className}
      css={props.composeCss ? [card, props.composeCss] : card}
    >
      {props.children}
    </section>
  )
}
