import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

let {
  // borderColor,
  size,
  radius,
  backgroundColor,
} = mTheme

export const card: Style = css({
  display: 'flex',
  flexDirection: 'column',
  gap: size.S,
  alignItems: 'stretch',
  textAlign: 'left',
  padding: size.S,
  borderRadius: radius.primary,
  backgroundColor: backgroundColor.card,
  userSelect: 'none',
  boxShadow: `${backgroundColor.primary} 0px 8px 24px`,
})
